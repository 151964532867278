'use client';

import { useEffect, useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { Button, Input, Text, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DevTool } from '@hookform/devtools';

import { FormItem } from '@/components/Form';
import { Logo } from '@/components/Icon';
import { env } from '@/constants/env';
import { login } from '@/lib/api/client/auth';
import { LoginRequest } from '@/lib/api/schema/loginRequest';
import { storeToken } from '@/lib/api/mutator/custom-instance';
import { GeneralError } from '@/lib/api/schema';
import { loginBody } from '@/lib/zod/auth';

export default function LoginContainer() {
  const router = useRouter();
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<LoginRequest>({
    resolver: zodResolver(loginBody),
    mode: 'onBlur',
  });

  const [isInvalid, setIsInvalid] = useState(false);

  const submitHandler = async () => {
    setIsInvalid(false);
    try {
      const { token } = await login(getValues());
      if (!token) {
        throw new Error('認証エラー');
      }
      storeToken(token);
      router.push('/stores');
    } catch (error: unknown) {
      if ((error as GeneralError)?.status === 401) {
        setIsInvalid(true);
      }
    }
  };

  useLayoutEffect(() => {
    document.title = 'Canly | ログイン';
  }, []);

  useEffect(() => {
    localStorage.removeItem(env.localStorage.PERMISSION_KEY);
    localStorage.removeItem(env.localStorage.OPTION_KEY);
  }, []);

  return (
    <VStack gap={8}>
      <Logo size="xl" />
      <VStack
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        w={368}
        p={6}
        gap={4}
        borderRadius="md"
        backgroundColor="white"
        shadow="base"
      >
        <FormItem label="メールアドレス">
          <Input
            type="email"
            placeholder="入力してください"
            isInvalid={isInvalid}
            {...register('email')}
          />
        </FormItem>
        <FormItem label="パスワード">
          <Input
            type="password"
            placeholder="入力してください"
            isInvalid={isInvalid}
            {...register('password')}
          />
          {isInvalid && (
            <Text color="red.500">
              メールアドレスもしくはパスワードが誤っています。
            </Text>
          )}
        </FormItem>
        <Button
          w="full"
          mt={2}
          type="submit"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          ログイン
        </Button>
      </VStack>

      <DevTool control={control} />
    </VStack>
  );
}
