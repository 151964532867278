/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import {
  z as zod
} from '@/assets/i18n'


/**
 * メールアドレスとパスワードでログイン処理を行う。Bearer Token が返却されます。
 * @summary ログイン処理
 */
export const loginBodyEmailMax = 255;


export const loginBody = zod.object({
  "email": zod.string().email().min(1).max(loginBodyEmailMax),
  "password": zod.string().min(1)
})

export const loginResponse = zod.object({
  "token": zod.string()
})

